<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.product_order_options')">

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ProductsWrite)">
                <b-button variant="primary" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('keys.product_order_options.add_product_order_option') }}
                </b-button>
            </div>

            <Table ref="tableTest" v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="categories" @deleteRow="remove" @editRow="editRow" @edit="edit" v-on:itemAdded="loadData"/>
        </b-card>

        <AddProductOrderOption ref="addModal" :options="options" :productsRaw="products" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddProductOrderOption from '@/views/ProductOrderOption/AddProductOrderOption'
    import EditAdditionalOptions from '@/views/ProductOrderOption/EditAdditionalOptions'
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        /*eslint-disable-next-line*/
        components: {Table, AddProductOrderOption, BCard, BOverlay, BButton, editAdditionalOptions: EditAdditionalOptions},
        computed: {
            options() {
                return [
                    { id: 0, name: this.$t('table_fields.single_selection'), value: 'single_selection' },
                    { id: 1, name: this.$t('table_fields.required'), value: 'required' },
                    { id: 2, name: this.$t('table_fields.swap_with_equal'), value: 'swap_with_equal' },
                    { id: 3, name: this.$t('table_fields.swap_with_lower'), value: 'swap_with_lower' },
                    { id: 4, name: this.$t('table_fields.two_way_selection'), value: 'two_way_selection' },
                    { id: 5, name: this.$t('table_fields.free_order_options'), value: 'free_order_options' },
                    { id: 6, name: this.$t('table_fields.dont_multiply_with_product_qty'), value: 'dont_multiply_with_product_qty' }
                ]
            },
            columnDefs() {
                const hasPermissions = this.$hasPermission(this.$permissions.ProductsWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermissions,  field: 'name', filter: true},
                    { headerName: this.$t('table_fields.additional_options'), editable: hasPermissions, field: 'additional_options', filter: true, filterValueGetter: (data) => this.getOptions(data.data.additional_options), floatingFilterComponentParams:  {type: 'select', values: this.options, selectValue: 'name'}, cellEditorFramework: 'editAdditionalOptions', cellRenderer: (params) => this.getOptions(params.data.additional_options)},
                    { headerName: this.$t('table_fields.products'), editable: hasPermissions, field: 'product_ids', filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.products, multiple: true} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.products, multiple: true, selectValue: 'id', findValueInArray: true} }, cellRenderer: (params) => this.getProductsNames(params.value)}
                ]

                if (this.$hasPermission(this.$permissions.ProductsWrite)) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: {button: 'ProductOrderOptionItems'}, minWidth: 250}
                    )
                }

                return defs
            }
        },
        data() {
            return {
                categories: [],
                products: [],
                showLoader: false,
                arraysLoad: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/product_order_options/')
                    const response2 = this.$http.get('/api/client/v1/products/')

                    const responses = await Promise.all([response1, response2])
                    this.categories = responses[0].data ?? []
                    this.products = responses[1].data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/product_order_options/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/product_order_options/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getOptions(options) {
                if (!options) return  '/'
                const optionsToReturn = []
                for (const option of this.options) {
                    if (options[option.value] === 1) optionsToReturn.push(option.name)
                }
                if (optionsToReturn.length > 0) return optionsToReturn.join(', ')
                else return '/'
            },
            getProductsNames(value) {
                if (value && value.length > 0) return value.filter(opt => {
                    const item = this.products.find(x => x.id === opt && !x.disabled)
                    return !!item
                }).map(option => {
                    const item = this.products.find(x => x.id === option && !x.disabled)
                    if (item) return item.name
                }).join(', ')
                return '/'
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
