<template>
    <b-modal :title="params.column.colDef.headerName" v-model="modalActive" centered no-close-on-backdrop @hide="params.api.stopEditing(true)">
        <template #default>
            <TableWithCheckBox :columnDefs="columnDefs" :rowData="options" :selected="additionalOptions" :getAllItem="true" selectValue="value" :findValueInArray="true" @selectionChanged="selectionChanged"/>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="params.api.stopEditing()">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { BModal, BButton} from 'bootstrap-vue'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox'

    export default {
        components: {BModal, BButton, TableWithCheckBox},
        data() {
            return {
                value: null,
                modalActive: false,
                options: [
                    { name: this.$t('table_fields.single_selection'), value: 'single_selection' },
                    { name: this.$t('table_fields.required'), value: 'required' },
                    { name: this.$t('table_fields.swap_with_equal'), value: 'swap_with_equal' },
                    { name: this.$t('table_fields.swap_with_lower'), value: 'swap_with_lower' },
                    { name: this.$t('table_fields.two_way_selection'), value: 'two_way_selection' },
                    { name: this.$t('table_fields.free_order_options'), value: 'free_order_options' },
                    { name: this.$t('table_fields.dont_multiply_with_product_qty'), value: 'dont_multiply_with_product_qty' }
                ],
                columnDefs: [{ headerName: this.$t('table_fields.select'), field: 'name', editable: false, filter: true }],
                additionalOptions: []
            }
        },
        methods: {
            getValue() {
                const options = {}
                for (const option of this.options) {
                    const index = this.additionalOptions.findIndex(x => x.value === option.value)
                    if (index > -1) options[option.value] = 1
                }
                return options
            },
            selectionChanged(val) {
                this.additionalOptions = val
            }
        },
        mounted() {
            for (const option of this.options) {
                if (this.params.value[option.value] === 1) this.additionalOptions.push(option.value)
            }
            this.modalActive = true
        }
    }
</script>

<style scoped>
</style>